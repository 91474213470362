/****** Calling Button ******/
.callingButton-floating{
  width: fit-content;
  overflow: hidden;
  align-items: center;
  height: 80px;
  background: #111328;
  margin-top:5px;
  border-radius: 74.4px 0px 0px 74.4px;
  padding: 22.32px 0px;
  display:flex;
  position:fixed; 
  right:0; 
  z-index:101;
  
  >img{
    height: 84px;
    cursor: pointer;
    @media (max-width: 668px) {
      height: 65px;
      }

  }

  @media (max-width: 668px) {
  height: 60px;
  }

  >a{
    color:#FFFFFF;
     font-weight: 700;
     text-decoration:none;
     margin-right:10px;
  }
}