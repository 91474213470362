.buyAtHomeWrap {
    max-width: 550px;
    border: solid 1px #b7eac5;
    background-color: #f6fcf5;
    margin-left: 15px;
    margin-right: 14px;
    border-radius: 4px;

    .headerBuy {
        font-size: 18px;
        font-family: 'ProximaNovaBold';
        color: #389b0c;
        padding-top: 12px;
        padding-left: 14px;
    }

    .val {
        padding-left: 14px;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 1.5;
        font-family: 'ProximaNovaSemibold';
    }

    .btnBuyatHomeMob {
        margin-left: 12px;
        border: 1px solid #da2128;
        color: #fff;
        background-color: #da2128;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
        border-radius: 26px;
        font-family: 'ProximaNovaSemibold';
        padding-left: 12px;
        padding-right: 0px;
        margin-bottom: 12px;
        box-shadow: 0 0px 2px 0 rgba(218, 33, 40, 0.25), 2px 0 2px 0 rgba(218, 33, 40, 0.25);
    }

    .imgNewMob {
        width: 100%;
        height: 100%;
        padding-right: 12px;
    }

    .circleImg {
        margin-left: 5px;
    }
}

@media (max-width: 385px) {
    .buyAtHomeWrap .headerBuy {
        padding-top: 8px;
        margin-bottom: 5px;
    }

    .buyAtHomeWrap .val {
        line-height: 1.3;
    }

    .buyAtHomeWrap .col-7 {
        padding-left: 8px;
    }

    .buyAtHomeWrap .col-5 {
        padding-right: 8px;
    }
}

@media (max-width: 360px) {
    .buyAtHomeWrap .val {
        font-size: 11px;
    }
}

@media (max-width: 350px) {
    .buyAtHomeWrap {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 335px) {
    .buyAtHomeWrap .col-5 {
        padding-right: 5px;
    }

    .buyAtHomeWrap .val {
        font-size: 10px;
    }


}