.mobile-hidden {
  @media (max-width: 575px) {
    display: none;
  }
}

.desktop-hidden {
  @media (min-width: 576px) {
    display: none;
  }
}