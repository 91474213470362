.faq-container {
  max-width: 800px;
  margin: 0 auto;

  .MuiExpansionPanel-root {
    &.faq-home {
      margin-bottom: 6px;
      box-shadow: none;
      border-radius: 2px;
      border: solid 1px #eeeeee;
      background-color: #ffffff;

      &::before {
        background-color: transparent;
      }
    }

    .MuiExpansionPanelSummary-root {
      &.faq-question {
        font-family: 'ProximaNovaRegular';
        font-size: 16px;
        color: #333333;
        //height: 60px;
      }

      .faq-expand-arrow {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #f9f8fd;

        .arrow-red {
          margin-top: 3px;
          margin-left: -1px;
        }
      }
    }

    .MuiExpansionPanelDetails-root.faq-answer {
      line-height: 1.5;
      color: #333333;
      padding-top: 0;
      margin-top: -5px;
      display: block;

      p {
        margin-bottom: 5px;
      }
    }
  }
}