#Testimonial {
  max-width: 1160px;
  margin: 0px auto;

  .slick-slide {
    padding-right: 30px;

    .card {
      height: 474px !important;
      overflow: hidden;
    }
  }

  .slick-prev {
    left: -19px;
  }

  .slick-next {
    right: -19px;
  }

  .testiIcons {
    width: 124px;
    height: 120px;
    display: inline-block;
    margin-bottom: 28px;
    overflow: hidden;
  }

}

@media (max-width: 1299.98px){
  #Testimonial .slick-slide .card{
    height: 512px !important;
    overflow: hidden;
  }
}

@media (max-width: 1049px) {
  #Testimonial .slick-slide .card{
    height: 560px !important;
  }
}

@media (max-width: 1023.98px) {
  #Testimonial .slick-slide {
    padding-right: 20px;
  }
}

@media (max-width: 1299.98px) {
  #Testimonial {
    .customCardPadding {
      padding: 38px 25px !important;
    }
  }
}

@media (max-width: 991.98px) {
  #Testimonial .customCardPadding {
    padding: 38px 18px !important;
  }
}

@media (max-width: 913px) {
  // #Testimonial .slick-slide .card{
  //   height: 590px !important;
  // }
  #Testimonial .testiIcons {
    margin-bottom: 0PX;
  }
}

@media only screen and (min-device-width: 660px) and (max-device-width: 900px) and (-webkit-min-device-pixel-ratio: 2) {
  #Testimonial .slick-slide .card {
    height: 500px !important;
  }
}

// @media (max-width: 900px) {
//   #Testimonial .slick-slide .card{
//     height: 455px !important;
//   }
// }

// @media (max-width: 780px) {
//   #Testimonial .slick-slide .card{
//     height: 500px !important;
//   }
// }
// @media (max-width: 661px) {
//   #Testimonial .slick-slide .card{
//     height: 550px !important;
//   }
// }

// @media (max-width: 600px){
//   #Testimonial .slick-slide .card{
//     height: 420px !important;
//   }
// }
// @media (max-width: 480px){
//   #Testimonial .slick-slide .card{
//     height: 520px !important;
//   }
// }
// @media (max-width: 396px){
//   #Testimonial .slick-slide .card{
//     height: 580px !important;
//   }
// }
// @media (max-width: 356px){
//   #Testimonial .slick-slide .card{
//     height: 640px !important;
//   }
// }

// @media (max-width: 333px){
//   #Testimonial .slick-slide .card{
//     height: 660px !important;
//   }
// }

@media only screen and (min-device-width: 320px) and (max-device-width: 366px) and (-webkit-min-device-pixel-ratio: 2) {
  #Testimonial .slick-slide .card{
    height: 666px !important;
  }
}
@media (max-width: 699.98px) {
  #Testimonial.franchiseTestimonial .slick-slide .card {
    height: 535px !important;
  }
}