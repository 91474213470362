#countDown {
  max-width: 1540px;
  margin: 0px auto;

  h2 {
    font-size: 40px;
    color: #da2128;
  }

  .card {
    box-shadow: none;
    border: none;
  }

  .seprator {
    border-right: 1px solid #eee;
  }

  .countDown {
    display: inline-flex;
    width: 100%;
  }

  .facebook-icon {
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/facebook-icon.svg);
  }

  .google-icon {
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/google-icon.svg);
  }

  .facebook-icon,
  .google-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-size: 100%;
    position: relative;
    top: 12px;
    right: 6px;
  }

  .ratings-icon {
    width: 155px;
    height: 28px;
    display: inline-block;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/ratings-icon.svg);
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.citiesCount {
  font-size: 24px !important;
  width: 220px;
  display: inline-block;
}
