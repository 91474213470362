.card-slider-link {
  a {
    &:hover {
      color: currentColor !important;
      text-decoration: none !important;
    }
  }
}

.Mui-selected.tab-selected {
  color: #da2128;
}

.MuiTab-root.budget-tab {
  min-width: 90px;
}

.MuiTab-wrapper.tab-heading {
  font-family: 'ProximaNovaSemibold';
  font-size: 16px;
}

.MuiTabs-root.tab-track {
  border-bottom: 1px solid #dee2e6;
}