/* --- discover your bike ---*/

.cardForCategory {
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s;
  background-color: #ffffff;
}

.categoryCustomWrapper {
  max-width: 1060px !important;
}

.sectionMarginTop {
  margin-top: 40px;
}

// .categoryMobWrapper {
//   max-width: 800px;
// }

br.responsive {
  display: none;
}

.sectionPaddingCategory {
  padding-bottom: 31px;
}

.newcustomCardPadding {
  padding: 29px !important;
}

.buyBikeIcon {
  width: 79px;
  height: 69px;
  background: url("../../assets/images/buy-bikee.svg");
  background-position: center;
}

.sellBikeIcon {
  width: 86px;
  height: 70px;
  background: url("../../assets//images/sell-icon.svg");
  background-position: center;

}

.exchangeBikeIcon {
  width: 119px;
  height: 71px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/exchange.svg);
  background-position: center;

}

.doorStepIcon {
  width: 119px;
  height: 71px;
  background: url("../../assets/images/serviceIconNew.svg");
  background-size: cover;
  background-position: center;

}
.ownFranchiseIcon{
  background: url(../../assets/images/ownFranchise.svg);
  background-size: cover;
  background-position: center;
}
.buyBikeIcon,
.sellBikeIcon,
.exchangeBikeIcon,
.doorStepIcon {
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-bottom: 5px;
}
// .doorStepIcon {
//   background-repeat: no-repeat;
//   background-size: 100%;
//   display: inline-block;
//   margin-bottom: 0px;
//   // margin-top: -20px;
// }
.marginBottomTitle {
  margin-bottom: 7px;
}

.bikeCategoryCustomMob {
  cursor: pointer;
}

#bikesCategory .card:hover {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}

// #bikesCategory .cardForCategory:hover {
//   box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
// }

#bikesCategory a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

#bikeCardLink a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

@media only screen and (max-width: 780px) {
  #categorySub,
  .font16 {
    font-size: 14px !important;
  }

  .newcustomCardPadding {
    padding: 15px !important;
  }
 
}

@media only screen and(max-width: 668px) {
  .categoryMobWrapper {
    max-width: 1060px !important;
    width: 100%;
    //height: 205px;
    //padding: 0 30px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    padding: 15px 0;
  }
  
  // .homepagePaddingMuted{
  //   padding-left: 0px;
  //   padding-right: 0px;
  // }

  #categorySub,
  .font16 {
    font-size: 11px !important;
  }

  #category .heading {
    // text-align: left !important;
    font-size: 18px !important;
  }

  #bikesCategory .card {
    border-radius: 0px;
  }
  #bikesCategory .col-xs-4 {
    padding: 0px;
  }

  .col-sm-4,
  .col-4 {
    padding: 0px;
  }

  br.responsive {
    display: inline;
  }
  .sectionPaddingCategory {
    //padding-top: 10px;
    padding-bottom: 15px;
    //padding-left: 15px;
  }

  #bikesCategory .card {
    border-left: 0px;
    box-shadow: none;
  }

  .sectionMarginTop {
    margin-top: 20px;
  }

  #bikesCategory .card:hover {
    box-shadow: none;
  }
}

@media only screen and(max-width: 499px) {
  #categorySub,
  .font16 {
    font-size: 11px !important;
  }
  // .sectionPaddingCategory{
  //   padding-left: 15px;
  // }

  .newcustomCardPadding {
    padding-top: 15px !important;
  }

  // .categoryMobWrapper {
  //   height: 185px;
  //   padding: 0;
  // }

  .buyBikeIcon {
    max-width: 60px;
    max-height: 51px;
    width: 100%;
  }

  .sellBikeIcon {
    max-width: 64px;
    max-height: 51px;
    width: 100%;
  }

  .exchangeBikeIcon {
    max-width: 78px;
    max-height: 51px;
    width: 100%;
  }
  .doorStepIcon {
    max-width: 78px;
    max-height: 51px;
    width: 100%;
  }
 
}

@media only screen and(max-width: 399px) {
  // .categoryMobWrapper {
  //   padding: 0px;
  // }

  .sectionMarginTop {
    margin-top: 20px;
  }
}

/* --- New Bike Icons --*/

// #newbikesCategory a:hover {
//   text-decoration: none !important;
//   color: inherit !important;
// }

// .newBikeCategoryWrapper{
//   max-width: 800px;
//   width: 100%;
//   height: 176px;
//   margin: 0px auto;
//   // padding: 0 80px;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
//   background-color: #ffffff;
//   position: relative;
//   bottom: 10px;
//   text-align: center;
// }
// .newBikeCategoryWrapper::before{
//   content: "";
//   width: 15px;
//   height: 10px;
//   float: left;
//   position: absolute;
//   background:url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Triangle-left.svg) ;
//   left: -11px;
// }
// .newBikeCategoryWrapper::after{
//   content: "";
//   width: 15px;
//   height: 10px;
//   position: absolute;
//   background:url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Triangle-right.svg) ;
//   right: -14px;
//   top: 0px;
//   background-repeat: no-repeat;
// }

// .categoryIconWrapper{
//   width: 200px;
//   height: 176px;
//   display: inline-block;
//   // background: #f1f1f1;
//   text-align: center;
//   cursor: pointer;
//   transition: all .2s ease-in-out;
// }
// .categoryIconWrapper:hover{
//   background-color: #f1f1f1;
// }
// .buyiconwrap{
//   // width: 116px;
//   display: inline-block;
//   text-align: center;
//   // margin-right: 33px;
//   margin-top: 26px;
//   padding-top: 15px;
//   .buyBikeIcon {
//     width: 85px;
//     background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/buy-bikee.svg) no-repeat;
//     height: 68px;
//     display: inline-block;
//     // background-size: 100%;
//   }
// }
// br.responsive{
//   display: none;
// }

// h2.headerCategory{
//   display: none;
// }
// // .buyiconwrap:hover,.selliconWrap:hover,.exchangeiconWrap:hover,.serviceiconWrap:hover{
// //   background-color: #f1f1f1;
// // }

// .selliconWrap{
//   // width: 132px;
//   display: inline-block;
//   text-align: center;
//   // margin-right: 33px;
//   margin-top: 21px;
//   padding-top: 15px;
//     .sellBikeIcon {
//       width: 92px;
//       background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/sell-icon.svg) no-repeat;
//       height: 73px;
//       background-size: 100%;
//       display: inline-block;
//     }
//   }
//   .exchangeiconWrap{
//     // width: 150px;
//     display: inline-block;
//     text-align: center;
//     // margin-right: 33px;
//     margin-top: 24px;
//     padding-top: 15px;
//       .exchangeBikeIcon {
//         width: 119px;
//         background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/exchange.svg) no-repeat;
//         height: 70px;
//         display: inline-block;
//         // background-size: 100%;
//       }
//     }
//   .serviceiconWrap{
//     // width: 110px;
//     display: inline-block;
//     text-align: center;
//     margin-top: 17px;
//     padding-top: 15px;
//       .serviceIcon {
//         width: 79px;
//         background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/service1.svg) no-repeat;
//         height: 77px;
//         display: inline-block;
//         // background-size: 100%;
//       }
//   }

// // category section media queries started from here //

// @media only screen and (max-width: 800px){
//   .newBikeCategoryWrapper{
//     bottom: 0;
//   }
//   .categoryIconWrapper{
//     width: 180px;
//   }
// }
// @media only screen and (max-width: 721px){
//   .newBikeCategoryWrapper{
//     margin-top: 25px;
//     height: 176px;
//   }

//   .categoryIconWrapper{
//     width: 170px;
//     border:1px solid #eeeeee;
//     border-left: 0px;
//     //border-top: 0;
//     border-bottom: 0;

//   }
//   .categoryIconWrapper:nth-child(1){
//     border-left: 1px solid #eeeeee;
//   }
//   br.responsive{
//     display: inline;
//   }
// }
// @media only screen and (max-width: 600px){
//   .newBikeCategoryWrapper{
//     padding-top: 15px;
//     height: 206px;
//   }
//   .categoryIconWrapper .font14{
//     font-size: 11px !important;
//   }
//   .buyiconwrap .buyBikeIcon{
//     width: 60px;
//     height: 51px;
//     background-size:100%;
//   }
//   .selliconWrap .sellBikeIcon{
//     width: 64px;
//     height: 51px;
//     background-size:100%;
//   }
//   .exchangeiconWrap .exchangeBikeIcon{
//     width: 78px;
//     height: 49px;
//     background-size:100%;
//   }
//   .serviceiconWrap .serviceIcon{
//     width: 50px;
//     height: 51px;
//     background-size:100%;
//   }
//   .buyiconwrap, .selliconWrap, .exchangeiconWrap, .serviceiconWrap{
//     margin-top: 0;
//     padding-top: 20px;
//   }
//   .exchangeiconWrap{
//     margin-top: 2px;
//   }
//   .categoryIconWrapper{
//     padding: 0px;
//     height: 125px;
//     border-bottom: 1px solid #eeeeee;
//   }
//   h2.headerCategory{
//     display: inline-block;
//     text-align: left !important;
//     text-indent: 15px;
//     padding-bottom: 8px;
//   }
// }
// @media only screen and (max-width: 330px){

//   .buyiconwrap .buyBikeIcon{
//     width: 56px;
//   }
//   .selliconWrap .sellBikeIcon{
//     width: 60px;
//   }
//   .exchangeiconWrap .exchangeBikeIcon{
//     width: 74px;
//   }
//   .serviceiconWrap .serviceIcon{
//     width: 48px;
//   }

// }
// .buyBikeIcon,
// .sellBikeIcon,
// .serviceIcon,
// .exchangeBikeIcon {
//   background-repeat: no-repeat;
//   background-size: 100%;
//   display: inline-block;
//   margin-bottom: 5px;
//   height: 70px;

//   @media only screen and (max-width: 673px){
//     margin-top: 8px;
//     margin-bottom: 0px;
//   }
// }

/* --- Budget Bikes ---*/
#budgetBikesTabContent {
  margin-top: 25px;
}

.viewMoreArrow {
  width: 29px;
  height: 29px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/right-arrow-view.svg);
  display: inline-block;
  background-repeat: no-repeat;
}

.viewMoreLink {
  color: #da2128;
  display: inline-block;
}

.viewMoreWrapper {
  top: 40%;
  left: 0;
  right: 0;
}

/* --- search by brands ---*/
.bikesLogo {
  width: 80px;
  height: 60px;
  display: inline-block;
  margin-bottom: 9px;
  margin-top: 10px;
}

.bikesLogoExchange {
  width: 40px;
  // height: 30px;
  display: inline-block;
  margin-bottom: 9px;
  margin-top: 10px;
}

#serachByBrands {
  .slick-slide {
    padding-right: 24px;
    padding-left: 6px;
  }

  .slider .slick-prev {
    left: -20px;
  }

  .slider .slick-next {
    right: -20px;
  }

  img {
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(0.7);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(0.7);
    /* Microsoft Edge and Firefox 35+ */
    transition: all 0.2s ease-in-out;
  }

  .brandCard:hover img {
    -webkit-filter: grayscale(0);
    filter: none;
  }
}

a.no-link-decoration {
  text-decoration: none;

  &:hover {
    color: currentColor;
  }
}


.bg-carousal-placeholder-desktop{
  height: 24.3vw;
  min-height: 24.3vw;
}

.bg-carousal-placeholder-mobile{
  height: 55.7vw;
  min-height: 55.7vw;
}
