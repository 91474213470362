.enhancedPopularSell {
    padding: 0px 101px 30px;
}

@media(max-width: 1329px) {
    .enhancedPopularSell {
        padding: 0px 53px 30px;
    }
}

@media (max-width: 549px){
.enhancedPopularSell {
    padding: 0px 33px 30px;
}
}

@media (max-width: 440px){
    .enhancedPopularSell {
        padding: 0px 15px 15px;
    }
}